import Vue from 'vue';
import App from './App.vue';
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import 'material-design-lite';
import 'material-design-lite/material.min.css';
// import 'vue-material/dist/vue-material.min.css';
import './assets/fonts/maisfuturo/maisfuturo-font.css';
import './app.css';

import router from "./router";

Vue.config.productionTip = false;

export default new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
