<template>
  <div class="h-100">
    <router-view />
  </div>
</template>

<script>

const checkIfIsIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}
const checkIfIsMac = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /macintosh/.test(userAgent);
}
const checkIfIsSamsungBrowser = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /samsungbrowser/.test(userAgent);
}
const checkIfWindows = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /windows/.test(userAgent);
}
const checkIfChromeAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /chrome/.test(userAgent) && /android/.test(userAgent)
}

export default {  
  created() {
    localStorage.isIos = checkIfIsIos()
    localStorage.isMac = checkIfIsMac()
    localStorage.isWindows = checkIfWindows()
    localStorage.isChromeAndroid = checkIfChromeAndroid()
    localStorage.isSamsungBrowser = checkIfIsSamsungBrowser()
  },
};
</script>
 