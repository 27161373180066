import firebase from "firebase/app";
import firebaseConfig from './firebase-config.json';
firebase.initializeApp(firebaseConfig.result.sdkConfig);
// firebase.analytics();
import FirebaseHelper from './FirebaseHelper';

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const childrens = [
  {
    path: "/",
    component: () => import('./views/welcome/Welcome.vue')
  },
  {
    path: "simulador",
    component:() => 
        import('./views/simulador/index.vue'),
  },
  {
    path: "criar-conta",
    component:() => 
        import('./views/create/Create.vue'),
  },
  {
    path: "confirmacao-pagamento",
    component:() => 
        import('./views/payment/Payment.vue'),
  },
  {
    path: "sucesso",
    component:() => 
        import('./views/finish/Finish.vue'),
  },
  {
    path: "contrato",
    component:() => 
        import('./views/contrato/Contrato.vue'),
  },
  {
    path: "hunter-invalido",
    component:() => 
        import('./views/hunterCode/notFoundCode.vue'),
  },
  {
    path: "indicacao-invalida",
    component:() => 
        import('./views/hunterCode/notFoundCode.vue'),
  }
]

let params = {planos: []}
let routes = []

const router = new VueRouter({
  routes: routes,
  mode: "history"
});

//Carrega parametros gerais da adesão
let firebaseHelper = new FirebaseHelper();
firebaseHelper.getParametrosAdesao().then((parametros) => {
  //parte 1 - parametros gerais
  Object.keys(parametros.planos).forEach((plano) => {
    params.planos.push(plano)
    params[plano] = {}
    params[plano]['chat'] = parametros.planos[plano].chat
    params[plano]['empresas'] = parametros.planos[plano].empresas
    params[plano]['hunters'] = parametros.planos[plano].hunters
    params[plano]['identidade_visual'] = parametros.planos[plano].identidade_visual
    params[plano]['mensagens'] = parametros.planos[plano].mensagens
    params[plano]['simulador'] = parametros.planos[plano].simulador    
  })
  
  sessionStorage.clear()

  //Parte 2. Monta rotas dinamicamente
  for (let rota in parametros.parametros.rotas) {
    router.addRoute(parametros.parametros.rotas[rota])
  }
  //inclui rota padrão por plano
  router.addRoute({
    path: "/:plano", 
    component: () => import("./App.vue"),
    beforeEnter: (to, from, next) => {
      validaRota(to, from, next)
    },
    children: childrens
  })
  //inclui rota padrão por plano+empresa
  router.addRoute({
    path: "/:plano/:empresa", 
    component: () => import("./App.vue"),
    beforeEnter: (to, from, next) => {
      validaRota(to, from, next)
    },
    children: childrens
  })

})

function isValid (plan, emp) {
  let valid = params.planos.find(element => element.toLowerCase() === plan.toLowerCase())
  let validEmpresa
  if(valid) {
    let param = {... params[plan]}
    if (params[plan].empresas && emp) {
      validEmpresa = params[plan].empresas.find(element => element.empresa.toUpperCase() === emp.toUpperCase())
    } else if (emp) {
      validEmpresa = false
    }
    if (validEmpresa) {
      param[emp] = validEmpresa
    }
    sessionStorage.param = JSON.stringify(param)
  }
  return {plano: valid ? true : false, empresa: validEmpresa ? true : false}
}

function validaRota(to, from, next) {
  to.params.plano = ajustaPlano(to.params.plano)
  if(to.query.idHunter){
    //valida se hunter é valido
    let valid = params[to.params.plano].hunters.find(element => element.id == to.query.idHunter)
    if(valid) {
      if (to.fullPath !== from.fullPath) {
        return next();
      }
    } else {
      next({ path: `/${to.params.plano}/hunter-invalido` });
    }
  } else if (to.query.indicacao) {
    //TODO: incluir passo para validação da chave de indicação, de momento validando apenas o formato
    let chave = to.query.indicacao.split('-')
    let valid = chave.length === 2 && !isNaN(chave[0]) && !isNaN(chave[1]) && Number(chave[0]) < 10
    if(valid) {
      if (to.fullPath !== from.fullPath) {
        return next();
      }
    } else {
      next({ path: `/${to.params.plano}/indicacao-invalida` });
    }
  }
  let valid = isValid(to.params.plano, to.params.empresa)
  if (!valid.plano) { 
    next({ path: `/Mais Futuro` });
  }  
  if (to.params.empresa && !valid.empresa) {
    let verificaSeHunter = params[to.params.plano].hunters.find(element => element.id.toUpperCase() == to.params.empresa.toUpperCase())
    if (verificaSeHunter) {
      let path = `/${to.params.plano}?idHunter=${to.params.empresa}`
      if (to.query.midia) {
        path += `&midia=${to.query.midia}`
      }
      next({ path: path });
    } else {
      next({ path: `/${to.params.plano}` });
    }
  } 
  next();
}

function ajustaPlano(plano) {
  const planos = {
    'maisfuturo': 'Mais Futuro',
    'acprev': 'ACPrev',
    'copanprev': 'COPANPrev',
    'jmalucelli': 'JMalucelli',
    'dentaluniprev': 'DentalUNIPrev',
    'abepomprev': 'ABEPOMPrev',
    'oabprevpr': 'OABPrev-PR'
  }

  if (planos[plano.toLowerCase()]) {
    return planos[plano.toLowerCase()]
  } else {
    return plano
  }
}

export default router;
