'use strict';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

export default class FirebaseHelper {

    constructor() {
        // Firebase SDK.
        this.database = firebase.database();
        this.storage = firebase.storage();
        this.auth = firebase.auth();
        this.project = firebase.name
        this.functions = firebase.functions();
        // Firebase references that are listened to.
        this.firebaseRefs = [];
    }
    
    getFatorIdadeAdesao(plano,idade) {
        let ref = this.database.ref(`settings/simulador_seguro/${plano}/fator_idade/${idade}`)
        return ref.once('value').then((data) => {
            if (data.val()) {
                return data.val()
            } else {              
                return { "fator_invalidez": 0.1, "fator_morte": 0.1 }
            }
        })
    }

    getUrlTermoPreAdesaoPlano(plano) {
        let ref = this.database.ref(`settings/adesao_digital/planos/${plano}/urlTermoPreAdesao`)
        return ref.once('value').then((data) => {
            if (data.val()) {
                return data.val()
            } else {
                return null
            }
        })
    }

    getUrlTermoPreAdesaoPlanoEmpresa(plano, nomeEmpresa) {
        let ref = this.database.ref(`settings/adesao_digital/planos/${plano}/empresas`)
        return ref.once('value').then((data) => {
            if (data.val()) {
                var empresa = data.val().find(e => {
                    if (e.empresa.toUpperCase() === nomeEmpresa.toUpperCase()) {
                      return true
                    } else {
                      return null
                    }
                })
                return empresa.urlTermoPreAdesao
            } else {
                return null
            }
        })
    }

    getParametrosAdesao() {
        let ref = this.database.ref(`settings/adesao_digital`)
        return ref.once('value').then((data) => {
            if (data.val()) {
                return data.val()
            } else {
                return null
            }
        })
    }

    buscaCnpjEmpresa(plano, empresaCadastro) {
        let ref = this.database.ref(`settings/adesao_digital/planos/${plano}/empresas`)
        return ref.once('value').then((data) => {
            if (data.val()) {
                let empresa = (data.val() || {}).find(p => p.empresa.toUpperCase() === empresaCadastro.toUpperCase())               
                return (empresa || {}).cnpj || null
            } else {
                return null
            }
        })
    }

    getUrlAdesaoSettingsPlanos(plano) {
        let ref = this.database.ref(`settings/adesao_digital/planos/${plano}/url_redirect_lead`)
        return ref.once('value').then((data) => {   
          if (data.val()) {
            return data.val()
          } else {
            return null
          }
        })    
    }

    getPrazoMinimoSeguro(plano) {
        let ref = this.database.ref(`settings/simulador_seguro/${plano}/prazo_minimo_seguro`)
        return ref.once('value').then((data) => {
            if (data.val()) {
                return data.val()
            } else {
                return null
            }
        })
    }

    getParametrosSimuladorRenda(plano) {
        let ref = this.database.ref(`settings/simulador_renda/${plano}`)
        return ref.once('value').then((data) => {
            if (data.val()) {
                return data.val()
            } else {
                return null
            }
        })
    }

}